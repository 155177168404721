import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_US from '@common/locale-properties/en_US/en_US';
import de_DE from '@common/locale-properties/de_DE/de_DE';
import es_MX from '@common/locale-properties/es_MX/es_MX';
import fr_FR from '@common/locale-properties/fr_FR/fr_FR';
import no_NO from '@common/locale-properties/no_NO/no_NO';
import it_IT from '@common/locale-properties/it_IT/it_IT';
import en_GB from '@common/locale-properties/en_GB/en_GB';
import fr_CA from '@common/locale-properties/fr_CA/fr_CA';
import es_ES from '@common/locale-properties/es_ES/es_ES';
import nl_NL from '@common/locale-properties/nl_NL/nl_NL';
import da_DK from '@common/locale-properties/da_DK/da_DK';
import sv_SE from '@common/locale-properties/sv_SE/sv_SE';
import cs_CZ from '@common/locale-properties/cs_CZ/cs_CZ';
import fi_FI from '@common/locale-properties/fi_FI/fi_FI';
import pt_PT from '@common/locale-properties/pt_PT/pt_PT';
import hu_HU from '@common/locale-properties/hu_HU/hu_HU';
import pl_PL from '@common/locale-properties/pl_PL/pl_PL';
import ro_RO from '@common/locale-properties/ro_RO/ro_RO';
import el_GR from '@common/locale-properties/el_GR/el_GR';
import sl_SI from '@common/locale-properties/sl_SI/sl_SI';
import nl_BE from '@common/locale-properties/nl_BE/nl_BE';
import de_AT from '@common/locale-properties/de_DE/de_DE';
import de_CH from '@common/locale-properties/de_DE/de_DE';
import fr_CH from '@common/locale-properties/fr_FR/fr_FR';
import it_CH from '@common/locale-properties/it_IT/it_IT';
import en_IR from '@common/locale-properties/en_GB/en_GB';

i18n.use(initReactI18next).init({
  resources: {
    en_US,
    de_DE,
    es_MX,
    fr_FR,
    no_NO,
    it_IT,
    en_GB,
    fr_CA,
    es_ES,
    nl_NL,
    cs_CZ,
    da_DK,
    sv_SE,
    hu_HU,
    pl_PL,
    fi_FI,
    pt_PT,
    ro_RO,
    el_GR,
    sl_SI,
    nl_BE,
    de_AT,
    de_CH,
    fr_CH,
    it_CH,
    en_IR,
  },
  lng: 'en_US',
  fallbackLng: 'en_US',
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});
export default i18n;
