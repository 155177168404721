import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import AmplitudeService from '@common/services/amplitude.service';
import { straight_sculpt } from '@common/assets/images';

export interface DisclaimerModalProps {
  t: (arg0: string, arg1?: any) => string;
  isOpen: boolean;
  closeModal: () => void;
}
export interface DisclaimerModalState {}

class DisclaimerModal extends Component<DisclaimerModalProps, DisclaimerModalState> {
  readonly amplitudeService: AmplitudeService;
  constructor(props: DisclaimerModalProps) {
    super(props);
    this.state = {};
    this.amplitudeService = new AmplitudeService();
  }

  public render(): React.ReactNode {
    const { t, isOpen, closeModal } = this.props;
    return (
      <Modal isOpen={isOpen} className="disclaimer-modal">
        <div className="disclaimer-modal-header">
          <span data-testid="disclaimer-modal-title" className="disclaimer-modal-title">
            {t('r_disclaimer')}
          </span>
          <img src={straight_sculpt} className="disclaimer-modal-title-background-image"></img>
        </div>
        <div className="disclaimer-modal-body" data-testid="disclaimer-modal-body">
          <div className="disclaimer-modal-body-title">{t('r_pricing')}</div>
          <div className="disclaimer-modal-body-description">{t('r_pricing_desc')}</div>
          <div className="disclaimer-modal-body-title">{t('r_activation')}</div>
          <div className="disclaimer-modal-body-description">{t('r_activation_desc')}</div>
          <div className="disclaimer-modal-body-title">{t('r_charging_speeds')}</div>
          <div className="disclaimer-modal-body-description">{t('r_charging_speeds_desc')}</div>
        </div>
        <div className="disclaimer-modal-footer" data-testid="disclaimer-modal-footer">
          <button
            data-testid="disclaimer-modal-close-btn"
            className={'disclaimer_modal_close_btn'}
            onClick={() => closeModal()}>
            {t('close_button')}
          </button>
        </div>
      </Modal>
    );
  }
}

export default withTranslation('translations')(DisclaimerModal as any);
export { DisclaimerModal }; //(only for testing purpose).
