import React, { ReactElement } from 'react';
import { ca_retail_info_dark, ca_retail_info_icon_light } from '@common/assets/images';

export interface ConnectorSpeedTagProps {
  t: (arg0: string) => string;
  dataID: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

function MoreInfoButton(props: ConnectorSpeedTagProps): ReactElement {
  const { t } = props;

  return (
    <>
      <button
        data-testid={props.dataID}
        data-toggle="modal"
        className="more-info-button"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.onClick(e)}>
        <img
          src={ca_retail_info_icon_light}
          className="button light-retail-icon"
          data-testid="light-retail-icon"
          alt={t('more_information')}
        />
        <img
          src={ca_retail_info_dark}
          className="button dark-retail-icon"
          data-testid="dark-retail-icon"
          alt={t('more_information')}
        />
      </button>
    </>
  );
}

export default MoreInfoButton;
export { MoreInfoButton };
