import {
  API_CACHE_TYPE,
  API_CONTENT_TYPE,
  API_CREDENTIALS,
  API_MODE,
  API_METHODS,
} from '@common/enums';
import {
  SplunkObj,
  SplunkEventObj,
  SplunkMessageObj,
  APICallOptions,
  SplunkStartLogger,
  SplunkCompleteLogger,
  SplunkErrorLogger,
  SplunkInfoLogger,
  SplunkBody,
} from '@common/interfaces';
import { SPLUNK_SERVICE_STATUS } from '@common/enums/splunkService.enum';
import { LocalStorageUtils } from '@common/utilities';
import axios from 'axios';
export default class SplunkLogger {
  static SPLUNK_API_URL: string | any = process.env.REACT_APP_HMPC_API_SPLUNK_API;
  static HMPC_API_BACKEND: string | any =
    process.env.REACT_APP_HMPC_API_BASEPATH + process.env.REACT_APP_HMPC_API_BACKEND;
  public static postSplunkStatus = async (splunkEventObj: SplunkEventObj): Promise<any> => {
    const SplunkObj: SplunkObj = {
      sourcetype: 'hmpc-webapp',
      event: splunkEventObj,
    };
    const body: SplunkBody = {
      sourcetype: 'hmpc-webapp',
      event: SplunkObj,
    };
    const options: APICallOptions = {
      method: API_METHODS.POST,
      mode: API_MODE.CORS,
      cache: API_CACHE_TYPE.NO_CACHE,
      credentials: API_CREDENTIALS.SAME_ORIGIN,
      proxy: false,
      headers: {
        Accept: API_CONTENT_TYPE.JSON,
        'Content-Type': API_CONTENT_TYPE.JSON,
        url: SplunkLogger.SPLUNK_API_URL,
        env: process.env.REACT_APP_HMPC_ENV,
      },
      body: JSON.stringify(body),
    };

    options.headers.mode = options.mode;

    axios.post(SplunkLogger.HMPC_API_BACKEND, body, options);
  };

  public static getSplunkStartLoggerData(splunkStartLogger: SplunkStartLogger): SplunkMessageObj {
    return {
      progress: SPLUNK_SERVICE_STATUS.START,
      service: splunkStartLogger.service,
      functionName: splunkStartLogger.functionName,
      statusCode: '',
      statusDesc: '',
      chargingNetwork: splunkStartLogger.chargingNetwork,
      duration: splunkStartLogger.startTime,
      traceID: splunkStartLogger.traceID,
      spanID: splunkStartLogger.traceID,
      env: process.env.REACT_APP_HMPC_ENV,
      vinNumber: LocalStorageUtils.getVinNumber(),
      enrollmentType: LocalStorageUtils.getEnrollmentType(),
      correlationID: splunkStartLogger.traceID,
    };
  }

  public static getSplunkInfoLogger(splunkInfoLogger: SplunkInfoLogger): SplunkMessageObj {
    return {
      progress: SPLUNK_SERVICE_STATUS.INFO,
      service: splunkInfoLogger.service,
      functionName: splunkInfoLogger.functionName,
      statusCode: '',
      statusDesc: splunkInfoLogger.statusDesc,
      duration: splunkInfoLogger.logTime,
      traceID: splunkInfoLogger.traceID ? splunkInfoLogger.traceID : '',
      spanID: splunkInfoLogger.traceID ? splunkInfoLogger.traceID : '',
      env: process.env.REACT_APP_HMPC_ENV,
      vinNumber: LocalStorageUtils.getVinNumber(),
      enrollmentType: LocalStorageUtils.getEnrollmentType(),
      correlationID: splunkInfoLogger.traceID,
    };
  }

  public static getSplunkErrorLogger(splunkErrorLogger: SplunkErrorLogger): SplunkMessageObj {
    return {
      progress: SPLUNK_SERVICE_STATUS.ERROR,
      service: splunkErrorLogger.service,
      functionName: splunkErrorLogger.functionName,
      statusCode: splunkErrorLogger.statusCode,
      statusDesc: splunkErrorLogger.error,
      duration: splunkErrorLogger.completeTime,
      traceID: splunkErrorLogger.traceID,
      spanID: splunkErrorLogger.traceID,
      env: process.env.REACT_APP_HMPC_ENV,
      vinNumber: LocalStorageUtils.getVinNumber(),
      enrollmentType: LocalStorageUtils.getEnrollmentType(),
      correlationID: splunkErrorLogger.traceID,
    };
  }

  public static getCompleteLogger(splunkCompleteLogger: SplunkCompleteLogger): SplunkMessageObj {
    return {
      progress: SPLUNK_SERVICE_STATUS.COMPLETE,
      service: splunkCompleteLogger.service,
      functionName: splunkCompleteLogger.functionName,
      statusCode: splunkCompleteLogger.statusCode,
      statusDesc: splunkCompleteLogger.statusDesc,
      duration: splunkCompleteLogger.completeTime,
      traceID: splunkCompleteLogger.traceID,
      spanID: splunkCompleteLogger.traceID,
      env: process.env.REACT_APP_HMPC_ENV,
      vinNumber: LocalStorageUtils.getVinNumber(),
      enrollmentType: LocalStorageUtils.getEnrollmentType(),
      correlationID: splunkCompleteLogger.traceID,
    };
  }

  public static start(splunkStartLogger: SplunkStartLogger): void {
    const splunkMsgObj: SplunkMessageObj = this.getSplunkStartLoggerData(splunkStartLogger);
    const splunkEventObj: SplunkEventObj = {
      msg: splunkMsgObj,
    };
    this.postSplunkStatus(splunkEventObj);
  }

  public static complete(splunkCompleteLogger: SplunkCompleteLogger): void {
    const splunkMsgObj: SplunkMessageObj = this.getCompleteLogger(splunkCompleteLogger);
    const splunkEventObj: SplunkEventObj = {
      msg: splunkMsgObj,
    };
    this.postSplunkStatus(splunkEventObj);
  }

  public static error(splunkErrorLogger: SplunkErrorLogger): void {
    const splunkMsgObj: SplunkMessageObj = this.getSplunkErrorLogger(splunkErrorLogger);
    const splunkEventObj: SplunkEventObj = {
      msg: splunkMsgObj,
    };
    this.postSplunkStatus(splunkEventObj);
  }

  public static log(splunkInfoLogger: SplunkInfoLogger): void {
    const splunkMsgObj: SplunkMessageObj = this.getSplunkInfoLogger(splunkInfoLogger);
    const splunkEventObj: SplunkEventObj = {
      msg: splunkMsgObj,
    };
    this.postSplunkStatus(splunkEventObj);
  }
}
