import React, { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';

export interface OutOfNetworkMsgProps {
  t: (arg0: string) => string;
}

function OutOfNetworkMsg(props: OutOfNetworkMsgProps): ReactElement {
  const { t } = props;

  return (
    <div data-testid="out-of-network-msg" className="text-right out-of-network-msg">
      <p className="mb-0">
        {t('out_of_network_msg')}
        <br />
        {t('follow_instructions_msg')}
      </p>
    </div>
  );
}

export default withTranslation('translations')(OutOfNetworkMsg as any);
export { OutOfNetworkMsg };
