import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

export interface StopButtonProps {
  t: (arg0: string) => string;
  history: any;
}

class StopButton extends Component<StopButtonProps> {
  constructor(props: StopButtonProps) {
    super(props);
  }
  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <div className="stopButtonContainer" data-testid="stopButtonContainer">
        <button
          className="button--secondary stop-button-width"
          data-testid="stop-btn"
          onClick={() => {}}>
          {t('stop_charging')}
        </button>
      </div>
    );
  }
}
export default withTranslation('translations')(StopButton as any);
export { StopButton as StopButton }; //(only for testing purpose).
