import React, { useState, useEffect } from 'react';
import './DriversDistractionBanner.scss';

interface BannerProps {
  showBanner: boolean;
  t: (arg0: string) => string;
  closeBanner: () => void;
}

function DriversDistractionBanner({ showBanner, t, closeBanner }: BannerProps) {
  const [showBannerPopup, setShowBannerPopup] = useState(false);

  useEffect(() => {
    let timeout;
    setShowBannerPopup(showBanner);
    if (showBanner) {
      timeout = setTimeout(() => {
        closeBanner();
        setShowBannerPopup(false);
      }, 4000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [showBanner]);

  const dismissPopup = (): void => {
    closeBanner();
    setShowBannerPopup(false);
  };

  return (
    <>
      {showBannerPopup ? (
        <div
          className="alert alert-light driversDistractionBanner w-100"
          data-testid="driversDistractionBanner"
          role="alert">
          {t('driving_popup_text')}

          <button
            type="button"
            className="button--secondary"
            data-testid="distraction-dismiss-btn"
            onClick={dismissPopup}>
            {t('close_modal_button_alt_text')}
          </button>
        </div>
      ) : null}
    </>
  );
}

export default DriversDistractionBanner;
