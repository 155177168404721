import React, { Component, ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import {
  getConnectorStatusClassName,
  getConnectorStatusTextKey,
} from '@common/stores/connectorStatus.store';
import { ConnectorPriceInfo } from '../fragments/connectorPriceInformation/connectorPriceInfo';
import OutOfNetworkMsg from '../fragments/outOfNetworkMsg/outOfNetworkMsg';
import ConnectorImg from '../fragments/connectorImg/connectorImg';
import CARetailProgressBar from '../caRetailProgressBar/caRetailProgressBar';
import { getRangeCeiled } from '@common/utilities/conversions';
import LocalStorageUtils from '@common/utilities/localStorage';
import MoreInfoButton from '../buttons/moreInfoButton/moreInfoButton';
import { ChargingSpeedModal } from '../modals/chargingSpeedModal/chargingSpeedModal';
import { AMPLITUDE_CONSTANTS } from '@common/enums/amplitude.enum';
import AmplitudeService from '@common/services/amplitude.service';

export interface ChargingConnectorCardProps {
  connectorId: number | null;
  connectorStatus: string | null;
  connectorSpeed: number | null;
  connectorType: string | null;
  evseExtID: string | null;
  connectorPrice: string;
  t: (arg0: string) => string;
  netWorksAccepted: boolean;
  networkStatus: ReactElement | null;
  lessThan50?: boolean;
  regionCode?: boolean;
  batteryChargedPercent: number;
  vehicleIsCharging: boolean | null | undefined;
  currentBatteryRange: string;
  timeToCharge80PercentComplete: string | null;
  timeToCharge100PercentComplete: string | null;
  timeToCharge100PercentForAc: string | null;
  evsePowerLevel: string;
  preferredDistanceUnit: string;
  showProgressbar?: boolean;
  connectorIdentifierConfigs: Map<string, string>;
  evseId?: string;
  locationId?: string;
  nacsAdapterRequired: boolean;
}

export interface ChargingConnectorCardState {
  isChargingSpeedModalOpenStatus: boolean;
}

class ChargingConnectorCard extends Component<
  ChargingConnectorCardProps,
  ChargingConnectorCardState
> {
  readonly chargerID: string;
  readonly amplitudeService: AmplitudeService;

  constructor(props: ChargingConnectorCardProps) {
    super(props);
    this.state = {
      isChargingSpeedModalOpenStatus: false,
    };

    this.chargerID = this.getChargerID();
    this.amplitudeService = new AmplitudeService();
  }

  private getChargerID(): string {
    if (this.props.evseExtID) {
      return this.props.evseExtID;
    }
    if (this.props.connectorId) {
      return this.props.connectorId.toString();
    }
    return '';
  }

  private renderConnectorSpeed(t: any, speed: number): ReactElement {
    return (
      <p data-testid="connector-speed" className="text-base--34">
        {speed}
        {t('kW')}
      </p>
    );
  }

  private renderConnectorStatus(t: any, connectorStatus: string | null): ReactElement {
    return (
      <p data-testid="connector-status" className={getConnectorStatusClassName(connectorStatus)}>
        {' '}
        {t(`${getConnectorStatusTextKey(connectorStatus)}`)}
      </p>
    );
  }

  private renderChargingStatus(): ReactElement {
    return (
      <p data-testid="connector-status" className="text-green">
        {this.props.t('charging_in_progress_charging')}
      </p>
    );
  }

  currentlyIsCharging(): boolean {
    const connectorIdentifierConfigs = this.props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getConnectorIdentifierConfigs()) || {}
      : this.props.connectorIdentifierConfigs;
    return !!(
      !!this.props.vehicleIsCharging && connectorIdentifierConfigs['evseId'] === this.props.evseId
    );
  }
  private handleChargingSpeedButtonClick = (): void => {
    const amplitudeObj = {
      eventType: AMPLITUDE_CONSTANTS.CHARGING_SPEED_BUTTON_TAPPED,
      screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
    };
    this.amplitudeService.postAmplitudeData(amplitudeObj);
    this.setState({ isChargingSpeedModalOpenStatus: true });
  };

  private isCurrentChargingLocation(): boolean {
    const connectorIdentifierConfigs = this.props.vehicleIsCharging
      ? JSON.parse(LocalStorageUtils.getConnectorIdentifierConfigs()) || {}
      : this.props.connectorIdentifierConfigs;
    return !!(
      !!this.props.vehicleIsCharging &&
      connectorIdentifierConfigs['locationId'] === this.props.locationId
    );
  }

  private renderConnector(): ReactElement {
    const {
      t,
      netWorksAccepted,
      connectorPrice,
      connectorSpeed,
      connectorType,
      connectorStatus,
      networkStatus,
      showProgressbar,
      vehicleIsCharging,
    } = this.props;
    const connectorPriceInfoProps = {
      t: t,
      price: connectorPrice,
      connectorName: this.getChargerID(),
      isUnKnownAvailableCharger: !netWorksAccepted,
    };
    const isCharging = this.currentlyIsCharging();
    return (
      <div className="charger-stalls">
        {ConnectorImg({
          connectorId: Number(connectorType),
          regionCode: this.props.regionCode,
          isNacsAdapterRequired: this.props.nacsAdapterRequired,
        })}
        <div className="stall-details">
          <div className="label">{this.getChargerID()}</div>
          <div className="stall-properties">
            <div className="stall-availability">
              {isCharging
                ? this.renderChargingStatus()
                : this.renderConnectorStatus(t, connectorStatus)}
            </div>
            <div className="stall-speed">
              {!!connectorSpeed ? this.renderConnectorSpeed(t, connectorSpeed) : null}
            </div>
            <div className="stall-price">
              <ConnectorPriceInfo {...connectorPriceInfoProps} />
            </div>
          </div>
          {!netWorksAccepted ? (
            <div className="stall-status">
              <OutOfNetworkMsg />
            </div>
          ) : null}
        </div>
        {netWorksAccepted && this.props.lessThan50 ? <div> {networkStatus} </div> : null}
      </div>
    );
  }

  private renderProgressBar(): ReactElement {
    const { t, connectorSpeed } = this.props;
    const startBatteryRange = LocalStorageUtils.getStartingVehicleRange();
    const gained = `${getRangeCeiled(
      this.props.currentBatteryRange,
      startBatteryRange,
      this.props.preferredDistanceUnit
    )} ${t(this.props.preferredDistanceUnit)}`;
    return (
      <>
        {this.props.showProgressbar == true ? (
          <>
            <div data-testid="charging-propgres-bar" className="charging-propgres-bar">
              <div />
              <CARetailProgressBar
                t={t}
                batteryChargedPercent={this.props.batteryChargedPercent}
                timeToCharge80PercentComplete={this.props.timeToCharge80PercentComplete}
                timeToCharge100PercentComplete={this.props.timeToCharge100PercentComplete}
                vehicleIsCharging={this.props.vehicleIsCharging}
                timeToCharge100PercentForAc={this.props.timeToCharge100PercentForAc}
                evsePowerLevel={this.props.evsePowerLevel}
              />
              <div />
            </div>
            <div className="charging-speed" data-testid="charging-speed">
              <p className="gained-miles" data-testid="gained-miles">
                {t('gained')}: <strong className="font-600-bold">{gained}</strong>
              </p>
              {/* Uncomment below snippet when the current speed of the charger need to display  */}
              {/* <div className='speeds-container'>
                <p className='speeds' data-testid='current-speed'>{t('speed')}: {this.props.vehicleIsCharging ? <strong>{connectorSpeed} {t('kW')}</strong> : '--' + t('kW')}</p>
                <MoreInfoButton t={t} dataID="connector-speed-modal" onClick={this.handleChargingSpeedButtonClick} />
              </div> */}
            </div>
          </>
        ) : null}
      </>
    );
  }

  private renderRetailView(): ReactElement {
    const { t, showProgressbar } = this.props;
    const { isChargingSpeedModalOpenStatus } = this.state;
    const isCurrentChargingLocation = this.isCurrentChargingLocation();
    const blurInActiveCharger =
      showProgressbar != true && isCurrentChargingLocation ? 'blur-charger-item' : '';
    return (
      <>
        <div className={`charger-stalls-body ${blurInActiveCharger}`} data-testid="charger-stalls">
          {this.renderConnector()}
          {this.renderProgressBar()}
        </div>
        {isChargingSpeedModalOpenStatus && (
          <ChargingSpeedModal
            isOpen={isChargingSpeedModalOpenStatus}
            t={t}
            closeModal={() => this.setState({ isChargingSpeedModalOpenStatus: false })}
          />
        )}
      </>
    );
  }

  render(): React.ReactNode {
    return <>{this.renderRetailView()}</>;
  }
}

export default withTranslation('translations')(ChargingConnectorCard as any);
export { ChargingConnectorCard };
