import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';

export interface ErrorModalProps {
  t: (arg0: string) => string;
  isOpen: boolean;
  closeModal: () => void;
  errorModalDesc: string;
}

class ErrorModal extends Component<ErrorModalProps> {
  render(): React.ReactNode {
    const { isOpen, t, closeModal, errorModalDesc = 'default' } = this.props;
    const modalTitle =
      errorModalDesc === 'default' ? 'modal_error_title' : 'charging_unavailable_modal_customTitle';
    const modalDesc =
      errorModalDesc === 'default' ? 'error_text' : 'charging_unavailable_customError';
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="error-modal"
        closeTimeoutMS={500}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}>
        <div className="modal__header">
          <h2 data-testid="title" className="modal__header-title">
            {t(modalTitle)}
          </h2>
        </div>

        <div className="modal__body">
          <div className="modal__body-content">
            <p data-testid="description">{t(modalDesc)}</p>
          </div>

          <div className="modal__body-btn">
            <button data-testid="close-btn" onClick={closeModal} className="button--secondary">
              {t('ok')}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default withTranslation('translations')(ErrorModal as any);
export { ErrorModal };
