import React, { ReactElement } from 'react';
import {
  connector_type_7_dark,
  connector_type_7_light,
  ca_retail_ac_charger,
  ca_retail_ac_charger_dark,
  ca_retail_dc_charger,
  ca_retail_dc_charger_dark,
  connector_type_13_EU_dark,
  connector_type_13_EU_light,
  connectorID_8,
  adapter_needed,
  adapter_needed_dark,
} from '@common/assets/images';

export interface ConnectorImgProps {
  connectorID: number | null;
  regionCode: string | null;
  isNacsAdapterRequired: boolean;
}

function getDarkImages(id: number | null, region: string | null, isNacsAdapterRequired: boolean) {
  if (id === 7) {
    return connector_type_7_dark;
  } else if (id === 2) {
    return ca_retail_ac_charger_dark;
  } else if (id === 8) {
    return connectorID_8;
  } else if (id === 13) {
    if (region === 'EU') {
      return connector_type_13_EU_dark;
    } else {
      return ca_retail_dc_charger_dark;
    }
  } else if (isNacsAdapterRequired) {
    return adapter_needed_dark;
  }
  return ca_retail_ac_charger_dark;
}

function getLightImages(id: number | null, region: string | null, isNacsAdapterRequired: boolean) {
  if (id === 7) {
    return connector_type_7_light;
  } else if (id === 2) {
    return ca_retail_ac_charger;
  } else if (id === 8) {
    return connectorID_8;
  } else if (id === 13) {
    if (region === 'EU') {
      return connector_type_13_EU_light;
    } else {
      return ca_retail_dc_charger;
    }
  } else if (isNacsAdapterRequired) {
    return adapter_needed;
  }
  return ca_retail_ac_charger;
}

function ConnectorImg(props: ConnectorImgProps): ReactElement {
  const mode = window.document.documentElement.getAttribute('data-theme');
  function getConnectorIdIcon(id: number | null, region: string | null): any {
    if (mode === 'dark-retail') {
      return getDarkImages(props.connectorID, props.regionCode, props.isNacsAdapterRequired);
    } else {
      return getLightImages(props.connectorID, props.regionCode, props.isNacsAdapterRequired);
    }
  }

  return (
    <>
      <img
        src={getConnectorIdIcon(props.connectorID, props.regionCode)}
        alt="Connector Type Icon"
        className="connector-image"
        data-testid="connector-image"
      />
    </>
  );
}

export default ConnectorImg as any;
export { ConnectorImg };
