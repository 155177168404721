import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import { activate_QR_code } from '@common/assets/images';
import { LocalStorageUtils } from '@common/utilities';
import { COUNTRY_NETWORK_TEXT } from '@common/enums';

export interface BOCNModalProps {
  t: (arg0: string, options?: any) => string;
  isOpen?: boolean;
  closeModal?: () => void;
  regionCode: string;
}

export interface BOCNstate {}

class BOCNModal extends Component<BOCNModalProps, BOCNstate> {
  isFleetUser: boolean;
  constructor(props: BOCNModalProps) {
    super(props);
    this.isFleetUser = LocalStorageUtils.getAuthorizationType() === 'Fleet';
  }

  render(): React.ReactNode {
    const { t, closeModal, regionCode } = this.props;
    const countryCode: string = regionCode === 'NA' ? 'NA' : 'EU';
    const modalHeight = this.isFleetUser ? 'h-50' : 'h-75';

    return (
      <Modal
        isOpen={true}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        ariaHideApp={false}
        data-testid="bocn-modal"
        className={`bocn-modal ${modalHeight}`}
        shouldCloseOnOverlayClick={false}>
        <div className="modal__header" data-testid="bocn-modal-header">
          <h2 data-testid="title" className="modal__header-title">
            {t('bocn_modal_title')}
          </h2>
        </div>
        <div className="modal__body">
          <div className="modal__body-content">
            {!this.isFleetUser ? (
              <div>
                <div data-testid="description">{t('bocn_modal_desc')}</div>
                <div className="scan_info">{t('bocn_modal_scan_info')}</div>
                <div className="w-100 py-5 d-flex justify justify-content-center">
                  <img
                    src={activate_QR_code}
                    className="bocn-qr-code"
                    data-testid="light-retail-icon"
                    alt="qr-code"
                  />
                </div>
                <div data-testid="member-subscription" className="member-subscription">
                  <p data-testid="member-subscription-label" className="already-member-label">
                    {t('already_member')}
                  </p>
                  <p data-testid="ford-pass-toogle-label">{t('ford_pass_toogle_label')}</p>
                </div>
              </div>
            ) : (
              <div>
                <div data-testid="description-fleet">{t('bocn_modal_desc_fleet')}</div>
                <div className="scan_info">{t('bocn_modal_scan_info_fleet')}</div>
              </div>
            )}
          </div>
        </div>
        <div className="modal__footer">
          <button
            className="button--secondary my-3"
            data-testid="bocn-modal-close-btn"
            onClick={closeModal}>
            {t('close_button')}
          </button>
        </div>
      </Modal>
    );
  }
}
export default withTranslation('translations')(BOCNModal as any);
export { BOCNModal };
