import React, { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import { plug_in_dark_icon, plug_in_light_icon } from '@common/assets/images';

export interface PlugNChargeTagProps {
  t: (arg0: string) => string;
}

function PlugNChargeTag(props: PlugNChargeTagProps): ReactElement {
  const { t } = props;
  return (
    <div data-testid="plug-n-charge-tag" className="tag--plug-n-charge">
      <img
        src={plug_in_light_icon}
        className="plug-n-charge-padding-img light-icon"
        alt="Plug & Charge light icon"
        data-testid="plug-n-charge-light-icon"
      />
      <img
        src={plug_in_dark_icon}
        className="plug-n-charge-padding-img dark-icon"
        alt="Plug & Charge dark icon"
        data-testid="plug-n-charge-dark-icon"
      />
      {t('plug_and_charge')}
    </div>
  );
}

export default withTranslation('translations')(PlugNChargeTag as any);
export { PlugNChargeTag };
