import axios from 'axios';
import {
  API_CACHE_TYPE,
  API_CONTENT_TYPE,
  API_CREDENTIALS,
  API_METHODS,
  API_MODE,
} from '@common/enums';
import { APICallOptions } from '@common/interfaces/apiCalls.interface';
import BaseService from './baseService';
import { v4 as uuidv4 } from 'uuid';

export default class CvfeService extends BaseService {
  static CVFE_API_URL: string | any = process.env.REACT_APP_HMPC_API_CVFE_API;

  static HMPC_API_BACKEND: string | any =
    process.env.REACT_APP_HMPC_API_BASEPATH + process.env.REACT_APP_HMPC_API_BACKEND;

  static traceID = uuidv4();
  public async enrollmentType(): Promise<any> {
    const body = {};

    const options: APICallOptions = {
      method: API_METHODS.POST,
      mode: API_MODE.CORS,
      cache: API_CACHE_TYPE.NO_CACHE,
      credentials: API_CREDENTIALS.SAME_ORIGIN,
      headers: {
        Accept: API_CONTENT_TYPE.JSON,
        'Content-Type': API_CONTENT_TYPE.JSON,
        'x-b3-traceId': CvfeService.traceID,
        'x-b3-spanId': CvfeService.traceID,
        'x-correlation-id': CvfeService.traceID,
        url: CvfeService.CVFE_API_URL,
      },
    };
    options.headers.mode = options.mode;

    return await axios.post(CvfeService.HMPC_API_BACKEND, body, options);
  }
}
