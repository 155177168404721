import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

export interface ActivatingButtonProps {
  t: (arg0: string) => string;
  history: any;
}

class ActivatingButton extends Component<ActivatingButtonProps> {
  constructor(props: ActivatingButtonProps) {
    super(props);
  }
  render(): React.ReactNode {
    const { t } = this.props;
    return (
      <div className="activatingContainer" data-testid="activatingContainer">
        <button className="activate-button" data-testid="activate-btn" onClick={() => {}}>
          {t('activating')}
        </button>
      </div>
    );
  }
}
export default withTranslation('translations')(ActivatingButton as any);
export { ActivatingButton as ActivatingButton }; //(only for testing purpose).
