import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';

export interface StopChargingErrorModalProps {
  t: (arg0: string) => string;
  isOpen: boolean;
  closeModal: (isCancelled: boolean) => void;
  regionCode: string;
}

class StopChargingErrorModal extends Component<StopChargingErrorModalProps> {
  render(): React.ReactNode {
    const { t, isOpen, closeModal, regionCode } = this.props;
    return (
      <>
        <Modal
          isOpen={isOpen}
          closeTimeoutMS={10000}
          ariaHideApp={false}
          className="stop-charging-error-modal"
          shouldCloseOnOverlayClick={false}>
          <div data-testid="stop_charging_error_modal" className="modal__header">
            <h2 data-testid="title" className="modal__header-title">
              {t('stop_charging_error')}
            </h2>
          </div>
          <div className="modal__body">
            <div className="modal__body-content">
              <p data-testid="description">
                {regionCode == 'NA' ? t('modal_stop_charging_na') : t('modal_stop_charging_eu')}
              </p>
            </div>
          </div>
          <div className="modal__footer">
            <button
              className="button--secondary"
              data-testid="stop-charging-error-modal-close-btn"
              onClick={() => {
                closeModal(false);
              }}>
              {t('ok')}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
export default withTranslation('translations')(StopChargingErrorModal as any);
export { StopChargingErrorModal };
