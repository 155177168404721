import React, { Component } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';

export interface PriceInformationModalProps {
  t: (arg0: string) => string;
  isOpen: boolean;
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title: string;
  price: string;
}

class PriceInformationModal extends Component<PriceInformationModalProps> {
  render(): React.ReactNode {
    const { t, closeModal, isOpen, title, price } = this.props;
    return (
      <>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          closeTimeoutMS={500}
          ariaHideApp={false}
          className="price-info-modal"
          shouldCloseOnOverlayClick={false}>
          {!!this.props.title ? (
            <div className="modal__header">
              <h2 data-testid="title" className="modal__header-title">
                {t('charger')}&nbsp;{title}
              </h2>
            </div>
          ) : null}
          <div className="modal__body">
            <div data-testid="modal__body-content" className="modal__body-content">
              {price}
            </div>
          </div>
          <div className="modal__footer">
            <button
              className="button--secondary"
              data-testid="price-info-modal-close-btn"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => closeModal(e)}>
              {t('ok')}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
export default withTranslation('translations')(PriceInformationModal as any);
export { PriceInformationModal };
