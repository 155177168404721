import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { loading_spinner } from '@common/assets/images';
import { t } from 'i18next';

export interface SpinnerProps {
  t: (arg0: string) => string;
  history: any;
}

class Spinner extends Component<SpinnerProps> {
  constructor(props: SpinnerProps) {
    super(props);
  }
  render(): React.ReactNode {
    return (
      <div className="spinner-main-container">
        <div id="test-spinner" className="spinner-container" data-testid="spinner">
          <div data-testid="loading-text" className="loading-text">
            {t('loading').toString()}
          </div>
          <img src={loading_spinner} alt="Loading..." />
        </div>
      </div>
    );
  }
}
export default withTranslation('translations')(Spinner as any);
export { Spinner }; //(only for testing purpose).
