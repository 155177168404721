import { LocalStorageUtils } from '@common/utilities';
import { t } from 'i18next';
import React, { Component, ReactElement } from 'react';

interface CAProgressBarProps {
  vehicleIsCharging: boolean | null | undefined;
  t(translationString: string): string;
  batteryChargedPercent: any;
  timeToCharge80PercentComplete?: string | null;
  timeToCharge100PercentComplete?: string | null;
  timeToCharge100PercentForAc?: string | null;
  evsePowerLevel: string;
}

export default class CARetailProgressBar extends Component<CAProgressBarProps> {
  private renderChargingEstimates(): ReactElement {
    const { vehicleIsCharging, evsePowerLevel } = this.props;
    return (
      <>
        {vehicleIsCharging && evsePowerLevel === 'Regular'
          ? this.renderEstimatesForRegularChargers()
          : null}
        {vehicleIsCharging && evsePowerLevel !== 'Regular'
          ? this.renderEstimatesForFastChargers()
          : null}
      </>
    );
  }

  private renderEstimatesForFastChargers(): ReactElement {
    const { batteryChargedPercent, timeToCharge80PercentComplete, timeToCharge100PercentComplete } =
      this.props;
    return (
      <>
        {batteryChargedPercent >= 80 ? null : (
          <div data-testid="percent-line-80" className="percentage-line percent-line-80"></div>
        )}
        {batteryChargedPercent == 100 ? null : (
          <div className="percentage-line percent-line-100" data-testid="percent-line-100"></div>
        )}
        {batteryChargedPercent >= 80 ? null : (
          <div
            className="percent-line-text percent-line-80-text"
            data-testid="percent-line-80-text">
            <span>{t('80%').toString()} </span>
            <span>
              +{timeToCharge80PercentComplete} {t('min').toString()}
            </span>
          </div>
        )}
        {batteryChargedPercent == 100 ? null : (
          <div
            className="percent-line-text percent-line-100-text"
            data-testid="percent-line-100-text">
            <span>{t('100%').toString()} </span>
            <span>
              +{timeToCharge100PercentComplete} {t('min').toString()}
            </span>
          </div>
        )}
      </>
    );
  }

  private renderEstimatesForRegularChargers(): ReactElement {
    const { batteryChargedPercent, timeToCharge100PercentForAc } = this.props;
    return (
      <>
        {batteryChargedPercent == 100 ? null : (
          <div className="percentage-line percent-line-100" data-testid="percent-line-100"></div>
        )}
        {batteryChargedPercent == 100 ? null : (
          <div
            className="percent-line-text percent-line-100-text"
            data-testid="percent-line-100-text">
            <span>{t('100%').toString()} </span>
            <span>
              +{Math.round(Number(timeToCharge100PercentForAc) * 60)} {t('min').toString()}
            </span>
          </div>
        )}
      </>
    );
  }

  render() {
    const startingPercentage = LocalStorageUtils.getStartingVehicleChargePercentage();
    const newlyAddedPercentage = this.props.batteryChargedPercent;
    return (
      <>
        <div className="caretail-progressbar" data-testid="caretail-progressbar">
          <div className="container" data-testid="container">
            <div
              className="percentagebar current-percentage"
              data-testid="current-percentage"
              style={{ width: `${startingPercentage}%` }}></div>
            {
              <div
                className="percentagebar newly-added-percentage"
                data-testid="newly-added-percentage"
                style={{ width: `${newlyAddedPercentage}%` }}></div>
            }
          </div>
          {this.renderChargingEstimates()}
        </div>
      </>
    );
  }
}
