import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { isPriceMayVary, getMinifiedPriceText } from '@common/utilities';
import { MoreInfoButton } from '../../buttons/moreInfoButton/moreInfoButton';
import { PriceInformationModal } from '../../modals/priceInformationModal/priceInformationModal';

export interface ConnectorPriceInfoProps {
  t: (arg0: string) => string;
  price: string;
  connectorName: string;
  showPriceMayVary?: boolean;
  isUnKnownAvailableCharger?: boolean;
}

interface ConnectorPriceInfoState {
  isOpen: boolean;
}

class ConnectorPriceInfo extends Component<ConnectorPriceInfoProps, ConnectorPriceInfoState> {
  constructor(props: ConnectorPriceInfoProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  openModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    this.setState({ isOpen: true });
  };

  closeModal = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    this.setState({ isOpen: false });
  };

  render(): React.ReactNode {
    const {
      price = '',
      connectorName,
      t,
      showPriceMayVary = false,
      isUnKnownAvailableCharger = false,
    } = this.props;
    const { isOpen } = this.state;
    const isVary = isPriceMayVary(price);
    const priceMayVaryString = showPriceMayVary ? t('price_may_vary') : '';
    return (
      <>
        <p className="d-flex align-items-center connector-price-container">
          {isVary ? (
            <>
              <span data-testid="connector-price">
                {isUnKnownAvailableCharger
                  ? t('unknown_price')
                  : getMinifiedPriceText(price, priceMayVaryString)}
              </span>
              <MoreInfoButton dataID="connector-price-modal" t={t} onClick={this.openModal} />
            </>
          ) : (
            <span data-testid="connector-price">{price}</span>
          )}
        </p>
        <PriceInformationModal
          t={t}
          closeModal={this.closeModal}
          title={connectorName}
          price={price}
          isOpen={isOpen}
        />
      </>
    );
  }
}
export default withTranslation('translations')(ConnectorPriceInfo as any);
export { ConnectorPriceInfo };
