import React, { ReactElement } from 'react';
import {
  fast_charging_icon,
  regular_charging_icon,
  ultra_fast_charging_icon,
  fast_charging_icon_dark,
  regular_charging_icon_dark,
  ultra_fast_charging_icon_dark,
} from '@common/assets/images';

export interface ChargerSpeedIconProps {
  evsePowerLevel: string | null;
}

function ChargerSpeedIcon(props: ChargerSpeedIconProps): ReactElement {
  function getChargingSpeedIcon(speed: string): string[] {
    const iconSet = {
      Fast: [fast_charging_icon, fast_charging_icon_dark],
      'Ultra Fast': [ultra_fast_charging_icon, ultra_fast_charging_icon_dark],
      Default: [regular_charging_icon, regular_charging_icon_dark],
    };
    return iconSet[speed] ?? iconSet['Default'];
  }
  const [lightIcon, darkIcon] = getChargingSpeedIcon(props.evsePowerLevel ?? 'Default');
  return (
    <>
      <img
        src={lightIcon}
        alt="Charging Speed Icon light"
        className="charger-speed-icon light-icon"
        data-testid="charger-speed-icon-light"
      />
      <img
        src={darkIcon}
        alt="Charging Speed Icon"
        className="charger-speed-icon dark-icon"
        data-testid="charger-speed-icon-dark"
      />
    </>
  );
}

export default ChargerSpeedIcon as any;
export { ChargerSpeedIcon };
