import React, { Component, ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import { NavigationContext } from '@common/contexts/navigationContext';
import {
  ConnectorData,
  NavigationContextInterface,
  StationLocationObject,
} from '@common/interfaces';
import NavigationService from '@common/services/navigation.service';
import {
  right_turn_light_icon,
  right_turn_dark_icon,
  selected_star,
  unselected_star,
  retail_available_dark,
  retail_available_light,
  unselected_star_dark,
  caret_icon_light,
  caret_icon_dark,
  retail_star_selected_dark,
  retail_unavailable_dark,
  retail_unavailable_light,
} from '@common/assets/images';
import { Rating } from '@material-ui/lab';
import { convertMetersTo } from '@common/utilities';
import { AMPLITUDE_CONSTANTS } from '@common/enums/amplitude.enum';
import { AmplitudeObj } from '@common/interfaces/amplitudeData.interface';
import AmplitudeService from '@common/services/amplitude.service';
import './caRetailLocationHeader.scss';
import { PlugNChargeTag } from '../plugNChargeTag/plugNChargeTag';
import isStationNotCompatible from '@common/utilities/isStationNotCompatible';
import getFlagValue from '@common/utilities/getFeatureFlagValue';
import AdapterNeeded from 'src/components/locationCard/AdapterNeeded';
import isAdapterNeeded from '@common/utilities/getAdapterNedded';
import StationNotCompatible from '../stationNotCompatible/stationNotCompatible';
import ChargerSpeedIcon from '../chargerSpeedIcon/chargerSpeedIcon';

interface AvailableChargersCountProps {
  t: (arg0: string) => string;
  chargerLocation: StationLocationObject;
}

export function AvailableChargerCount(props: AvailableChargersCountProps): ReactElement {
  const { t, chargerLocation } = props;
  return (
    <div
      data-chargerid={chargerLocation.locationId}
      className="ca-available-charger-count"
      data-testid="available-charger-count">
      {chargerLocation.chargerAvailableCount === 0 ? (
        <>
          <img
            src={retail_unavailable_dark}
            className="available-network-image dark-icon"
            alt="Chargers Unavailable Icon"
            data-testid="unavailable-network-image-dark"
          />
          <img
            src={retail_unavailable_light}
            className="available-network-image light-icon"
            alt="Chargers Unavailable Icon"
            data-testid="unavailable-network-image-light"
          />
        </>
      ) : (
        <>
          <img
            src={retail_available_light}
            className="available-network-image light-icon"
            data-testid="available-network-image-light"
            alt="Available Chargers Icon"
          />
          <img
            src={retail_available_dark}
            className="available-network-image dark-icon"
            data-testid="available-network-image-dark"
            alt="Available Chargers Icon"
          />
        </>
      )}
      <span
        className={
          chargerLocation.chargerAvailableCount === 0
            ? 'empty-image retail-text-red'
            : 'empty-image-letter-spacing reail-text-success'
        }
        data-testid="reail-text-success">
        {chargerLocation.chargerAvailableCount} {t('of')} {chargerLocation.totalChargers}&nbsp;
        {t('available')}
      </span>
    </div>
  );
}

interface ChargerAvailabilityUnknownMsgProps {
  t: (arg0: string, arg1?: any) => string;
  charger: StationLocationObject;
}

export function ChargerAvailabilityUnknownMsg(props: ChargerAvailabilityUnknownMsgProps) {
  const { charger, t } = props;
  return (
    <p data-testid="charger-availability-unknown" className="charger-availability-unknown">
      {t('unknown_availability_msg', { count: charger.totalChargers })}
      {!charger.networksAccepted && <span> {t('unknown_availability_out_of_network')}</span>}
    </p>
  );
}

export interface CARetailLocationHeaderProps {
  t: (arg0: string) => string;
  ChargingLocationsObj: any;
  preferredDistanceUnit: string;
  navigationAvailable: boolean | null;
  history: any;
  lessThan50: boolean;
  setLessThan50: (arg: boolean) => void;
  isDriverRestrictionsActive: boolean;
  openDriverRestrictionsModal: () => void;
}

class CARetailLocationHeader extends Component<CARetailLocationHeaderProps> {
  readonly navigationService: NavigationService;
  readonly amplitudeService: AmplitudeService;
  constructor(props: CARetailLocationHeaderProps, context: NavigationContextInterface) {
    super(props);
    this.navigationService = new NavigationService();
    this.amplitudeService = new AmplitudeService();
  }

  goBack = (): void => {
    if (window.location.pathname === '/hmpcChargingSummary') {
      this.props.history.push('/');
    } else {
      window.history.back();
    }
    const amplitudeObj: AmplitudeObj = {
      eventType: AMPLITUDE_CONSTANTS.BACK_TAPPED,
      screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
    };
    this.amplitudeService.postAmplitudeData(amplitudeObj);
  };

  // //NOTE: future API changes will allow us to remove this
  connectorAvailabilityUnknown(): boolean {
    const connectorStatusArr: string[] = this.getConnectorStatusArray();
    let allConnectorsUnknownStatus = false;

    return connectorStatusArr.every((status: string) => {
      if (status === '0') {
        allConnectorsUnknownStatus = true;
        return allConnectorsUnknownStatus;
      }
    });
  }

  // //NOTE: future API changes will allow us to remove this
  getConnectorStatusArray(): string[] {
    const connectorStatusArr: string[] = [];
    const chargers = this.props.ChargingLocationsObj.chargers?.map((c: any) => c.connectors);
    const flatChargerArr = chargers?.flat();

    flatChargerArr?.forEach((connector: ConnectorData) => {
      if (!!connector && !!connector.connectorStatus) {
        connectorStatusArr.push(connector.connectorStatus);
      }
    });

    return connectorStatusArr;
  }

  private getDistanceToCharger(preferredUnit: string): string {
    return convertMetersTo(preferredUnit, this.props.ChargingLocationsObj.distance);
  }

  private showNavigationButton = (): any => {
    if (this.props.ChargingLocationsObj.distance > 50 && this.props.navigationAvailable) {
      return (
        <>
          <button
            data-testid="navigation-btn"
            className="retail-btn-secondary location-header-button"
            onClick={() => {
              const amplitudeObj: AmplitudeObj = {
                eventType: AMPLITUDE_CONSTANTS.NAVIGATION_BUTTON_TAPPED,
                screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
              };
              this.amplitudeService.postAmplitudeData(amplitudeObj);
              this.navigationService.startNavigation(this.props.ChargingLocationsObj);
            }}>
            <span data-testid="distance">
              {this.getDistanceToCharger(this.props.preferredDistanceUnit)}{' '}
              {this.props.t(this.props.preferredDistanceUnit)}
            </span>
            <img
              src={right_turn_light_icon}
              alt="Navigation Icon"
              className="navigation-icon light-icon"
              data-testid="navigation icon"
            />
            <img
              src={right_turn_dark_icon}
              alt="Navigation Icon"
              className="navigation-icon dark-icon"
              data-testid="navigation icon"
            />
          </button>
        </>
      );
    }
  };
  private handleIAmHere = (): void => {
    if (this.props.isDriverRestrictionsActive) {
      this.props.openDriverRestrictionsModal();
      return;
    }
    const amplitudeObj = {
      eventType: AMPLITUDE_CONSTANTS.IAM_HERE_NOW_BUTTON_TAPPED,
      screenName: AMPLITUDE_CONSTANTS.CHARGING_LOCATIONS,
    };
    this.amplitudeService.postAmplitudeData(amplitudeObj);
    this.props.setLessThan50(true);
  };

  render(): React.ReactNode {
    const { t } = this.props;
    const chargingLocationObj = this.props.ChargingLocationsObj;
    const showAdapterDiv = getFlagValue('tesla-changes');
    return (
      <>
        <div
          className={`header ${this.props.ChargingLocationsObj.score === null && 'header-only'}`}
          data-testid="location-header"
          onClick={this.goBack}>
          <h1 data-testid="header-label" className="header-label">
            <img
              data-testid="go-back-light"
              className="light-icon go-back-img"
              src={caret_icon_light}
              alt="go-back"
            />
            <img
              data-testid="go-back-dark"
              className="dark-icon go-back-img"
              src={caret_icon_dark}
              alt="go-back"
            />
            <span>{chargingLocationObj.locationName}</span>
          </h1>
        </div>
        <div className="body">
          <div className="stall-properties d-flex align-items-center gap-4">
            <div className="stall--speed d-flex align-items-center">
              <ChargerSpeedIcon evsePowerLevel={this.props.ChargingLocationsObj.evsePowerLevel} />
              <span className="text-base--34 pb-1">{t('speeds_upto')} &nbsp;</span>
              <span className="value text-base--34--bolded">
                {this.props.ChargingLocationsObj.chargers[0].maxConnectorSpd}
                {t('kW')}
              </span>
            </div>
            {!isStationNotCompatible(this.props.ChargingLocationsObj, showAdapterDiv) && (
              <>
                {isAdapterNeeded(this.props.ChargingLocationsObj, showAdapterDiv) && (
                  <AdapterNeeded t={this.props.t} />
                )}
                {chargingLocationObj.plugNCharge == true && <PlugNChargeTag t={t} />}
              </>
            )}
          </div>
          <div className="spacer-vertical"></div>
          {this.props.ChargingLocationsObj.score === null ? null : (
            <div data-testid="score">
              <Rating
                name="rating"
                className="light-section"
                icon={
                  <img className="star_header" src={selected_star} alt="rating star selected" />
                }
                emptyIcon={
                  <img className="star_header" src={unselected_star} alt="rating star unselected" />
                }
                max={5}
                precision={0.5}
                value={chargingLocationObj.score / 2}
                size="small"
                readOnly
              />
              <Rating
                name="rating"
                className="dark-section"
                icon={
                  <img
                    className="star_header"
                    src={retail_star_selected_dark}
                    alt="rating star selected"
                  />
                }
                emptyIcon={
                  <img
                    className="star_header"
                    src={unselected_star_dark}
                    alt="rating star unselected"
                  />
                }
                max={5}
                precision={0.5}
                value={chargingLocationObj.score / 2}
                size="small"
                readOnly
              />
            </div>
          )}
          <div className="address-container">
            <h3 className="text-base--34">
              {!!chargingLocationObj.address1 && (
                <span className="d-block" data-testid="address">
                  {chargingLocationObj.address1}&nbsp;
                </span>
              )}
              {!!chargingLocationObj.city && (
                <span data-testid="city">{chargingLocationObj.city},&nbsp;</span>
              )}
              {!!chargingLocationObj.state && (
                <span data-testid="state">{chargingLocationObj.state}&nbsp;&nbsp;</span>
              )}
              {!!chargingLocationObj.postalCode && (
                <span data-testid="postal-code">{chargingLocationObj.postalCode}</span>
              )}
            </h3>
            {isStationNotCompatible(this.props.ChargingLocationsObj, showAdapterDiv) && (
              <>
                <StationNotCompatible t={t} />
                <br />
              </>
            )}
          </div>

          {this.props.lessThan50 == true ? null : (
            <div data-testid="location-details" className="location-details">
              {!isStationNotCompatible(this.props.ChargingLocationsObj, showAdapterDiv) && (
                <>
                  <div className="location-total-availability">
                    {this.connectorAvailabilityUnknown() ? (
                      <ChargerAvailabilityUnknownMsg
                        t={t}
                        charger={this.props.ChargingLocationsObj}
                      />
                    ) : (
                      <AvailableChargerCount
                        t={t}
                        chargerLocation={this.props.ChargingLocationsObj}
                      />
                    )}
                  </div>
                </>
              )}
              <div data-testid="location-arrived" className="location-arrived">
                {this.showNavigationButton()}
                {!isStationNotCompatible(this.props.ChargingLocationsObj, showAdapterDiv) && (
                  <button
                    data-testid="i-am-here"
                    type="button"
                    onClick={this.handleIAmHere}
                    className={`retail-btn-secondary ${
                      this.props.isDriverRestrictionsActive ? 'opacity-50' : ''
                    }`}>
                    {t('i_am_here_now')}
                  </button>
                )}
              </div>
              {isStationNotCompatible(this.props.ChargingLocationsObj, showAdapterDiv) && (
                <div data-testid="no_compatible_stalls">
                  <h3 className="text-base--34">
                    <span className="text-base--34">{t('charger_stalls')}</span>
                    <div className="spacer-vertical" />
                    <div className="ca-divider"></div>
                    <span className="text-base--34">{t('no_compatible_stalls')}</span>
                  </h3>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

CARetailLocationHeader.contextType = NavigationContext;
export default withTranslation('translations')(CARetailLocationHeader as any);
export { CARetailLocationHeader };
